/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
 
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color: black !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.1s ease-in-out;
  background-color: white;
}
nav.navbar:hover {
 
  margin-top: 5px;
  border: 2px solid black;
  border-radius: 5px;
  
  top: 0;
  z-index: 9999;
  transition:  0.1s ease-in-out;
  background-color: white;
}
nav.navbar.scrolled {
  padding: 0px 0;
  
}
nav.navbar a.navbar-brand {
  width: 10%; 
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: white !important;
  background-color: black;
  letter-spacing: 0.8px;
  padding: 15px 25px;
  font-size: auto;
  opacity: 0.50;
}
nav.navbar .navbar-nav .nav-link.navbar-link:hover {
 
  border: 1px solid black;
  margin: 1px;
  transition: 0.5s ease-in-out;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    background-color: white;
    color: black !important;
}
span.navbar-text {
    display: flex;
    align-items: center;
    color: black;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
    color: green;
    
    padding: 5px;
}
.social-icon a {
    color: black !important;
    width: 42px;
    height: 42px;
    background: white;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 0px;
    align-items: left;
    justify-content: center;
    line-height: 2;
    border: 1px solid black;
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: black;
    
    border-radius: 50%;
    transform: scale(0);
	  transition: 0.3s ease-in-out;
   
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 50%;
	z-index: 1;
	transition: 0.3s ease-in-out;
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.social-icon a:hover img {
  
	filter: brightness(1) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: #fff1px solid ;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  color: black;
}
.navbar-text button span {
  z-index: 1;

}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
  
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  position: relative;
  border-bottom: 1px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 500px 0 200px 0;
  
  background-position: center;
  background-size: fit;
  background-repeat: no-repeat;
}
.banner:hover {

  background-repeat: repeat;
}
.banner-mobile {
  margin-top: 0;
  padding: 200px 0 0px 0;

  background-position: center;
  background-size: fit;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  background: black;
  color: white;
  font-Size: 50px;
  transition: .2s ease-in-out;
}

.banner-mobile .tagline-mobile {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, grey -5.91%, white 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  background-color: white;
  padding-left: 10px;
  color: black;
}
.banner-mobile h1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  background-color: black;
  padding-left: 10px;
  color: white;
}
.banner p {
  padding-left: 10px;
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width:100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.banner-mobile p {
  padding-left: 10px;
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width:100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 10s linear infinite;
}
.banner img:hover {
  animation: updown2 10s alternate ;
  
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes updown2 {
  0% {
      
      opacity: .1;
  }
  25% {
    transform: translateY(-200px);
  }
  35% {
    transform: translateY(200px);
  }
  45% {
    transform: translateY(10px);
  }
  50% {
      transform: translateY(20px);
     
  }
  
  100% {
    transform: translateY(0px);
    transform: translateX(0px);
   
}
}
.txt-rotate > .wrap {
  border-right: 0.0em solid #666;
}
.txt-rotate > .wrap-mobile {
  font-size: 20px;
}

/************ Skills Css ************/
.skill {
  padding: 200px 0 200px 0;
  position: relative;
  background-Color: white;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  color: white;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
  color: white;
}
.skill p {
    color: white;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
 
}
.skill-slider .item img {
  width: 25%;
  margin: 0 auto 50px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  color: white;
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: white;
}
.project p {
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 100%;
}
.project .nav.nav-pills {
  width: 100%;
  transition: 1s ease-in-out;
  margin: 0 auto;
  border-radius: 0px;
  background-color: white;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
.project .nav.nav-pills:hover {

  transition: 0.3s ease-in-out;
  margin: 0 auto;
  border-radius: 400px;
  background-color: white;
  overflow: hidden;
}
}
.project .nav.nav-pills .nav-item {
  width: 100%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0px;
  padding: 15px ;
  color: black;
  width: 100%;
  font-size: 20px;
  letter-spacing: 15px;
  font-weight: 500;
  position: relative;
  transition: 0.5s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
   
    position: absolute;
    width: 0;
    height: 100%;
    background: black;
    top: 0;
    right: 0;
    z-index: -1;
    transition: 0.3s ease-in-out ;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
   
}
.project .nav.nav-pills .nav-link.active {
  color: white;
  
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid black;
  
}
.nav-link#projects-tabs-tab-second {
  border: 1px solid black;
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid black;
  
}

@media only screen and (min-width: 768px) {

.nav-link#projects-tabs-tab-first:hover {
  padding-top: 100px;
  padding-botom: 50px;
  font-size: 50px;
  
}
.nav-link#projects-tabs-tab-second:hover {
  padding: 50px;
  font-size: 50px;
}
.nav-link#projects-tabs-tab-third:hover {
  padding-top: 50px;
  padding: 100px;
  font-size: 40px;
}
}
.proj-imgbx {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 24px;
  color:black;
  border: .5px solid white;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, grey -5.91%, white 111.58%);
  opacity: 0.95;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
 
}
.proj-imgbx:hover::before {
  height: 100%;
  width: 100%;
}
.proj-txtx {
 
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, white -5.91%, black 60.58%);
  padding: 200px 0 200px 0;
}
.contact img {
  width: 100%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color:  white;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
 
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: black;
  border-radius: 55px;
  color: white;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, black -5.91%, white 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, black -5.91%, grey 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
